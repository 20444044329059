import { CommonModule, DOCUMENT } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { Navigation, Pagination } from 'swiper/modules';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-review',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReviewComponent implements OnInit, AfterViewInit {
  @ViewChild('swiperContainer', { static: false })
  swiperContainer: ElementRef<SwiperContainer>;
  newsKeys: any[] = [];
  options: SwiperOptions = {
    modules: [Pagination, Navigation],
    pagination: { clickable: true },
    navigation: true,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe((event) => {
      this.getNewsTranslation();
    });
  }

  ngOnInit() {
    this.getNewsTranslation();
  }

  ngAfterViewInit() {
    if (this.swiperContainer && this.swiperContainer.nativeElement) {
      Object.assign(this.swiperContainer.nativeElement, this.options);
    }
  }

  getNewsTranslation() {
    this.translateService.get('main.mass-media.news').subscribe((res) => {
      this.newsKeys = [];
      const keys = Object.keys(res);
      for (let key of keys) {
        this.newsKeys.push({ key: key, showFullDescription: false });
      }
    });
  }

  toggleDescription(key: any): void {
    key.showFullDescription = !key.showFullDescription;
  }
}
