<section>
  <h2>{{ "main.mass-media.title" | translate }}</h2>
  <swiper-container #swiperContainer class="swiper">
    <swiper-slide class="mb-5" *ngFor="let obj of newsKeys">
      <div class="card">
        <div class="date">{{ 'main.mass-media.news.' + obj.key + '.date' | translate}}</div>
        <h3 class="title">{{ 'main.mass-media.news.' + obj.key + '.title' | translate}}</h3>
        <img class="card-img" [src]="'main.mass-media.news.' + obj.key + '.image' | translate" alt="" />
        <div class="description">
          <span *ngIf="!obj.showFullDescription">{{ 'main.mass-media.news.' + obj.key + '.description' | translate | slice : 0 : 200 }}...</span>
          <span *ngIf="obj.showFullDescription">{{ 'main.mass-media.news.' + obj.key + '.description' | translate }}</span>
        </div>
        <div class="more">
          <div class="line"></div>
          <button (click)="toggleDescription(obj)">
            {{ obj.showFullDescription ? "Скрыть" : "Подробнее" }}
          </button>
          <div class="line"></div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  
</section>
