import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';

@Component({
  selector: 'app-object-relatives',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './object-relatives.component.html',
  styleUrl: './object-relatives.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ObjectRelativesComponent {

  relatives: Object[] = [
    {
      photo: 'assets/object-portrait.jpg',
      title: 'Александров Владислав Максимильянович',
      years: '1926-2016',
      description: 'Муж ААлександрова Александры Валентиновны, родился в Деревне Иваново, в семье работников колхоза “50 лет ВЛКСМ”...'
    },
    {
      photo: 'assets/object-portrait.jpg',
      title: 'Александров Владислав Максимильянович',
      years: '1926-2016',
      description: 'Муж ААлександрова Александры Валентиновны, родился в Деревне Иваново, в семье работников колхоза “50 лет ВЛКСМ”...'
    },
    {
      photo: 'assets/object-portrait.jpg',
      title: 'Александров Владислав Максимильянович',
      years: '1926-2016',
      description: 'Муж ААлександрова Александры Валентиновны, родился в Деревне Иваново, в семье работников колхоза “50 лет ВЛКСМ”...'
    },
    {
      photo: 'assets/object-portrait.jpg',
      title: 'Александров Владислав Максимильянович',
      years: '1926-2016',
      description: 'Муж ААлександрова Александры Валентиновны, родился в Деревне Иваново, в семье работников колхоза “50 лет ВЛКСМ”...'
    },
    {
      photo: 'assets/object-portrait.jpg',
      title: 'Александров Владислав Максимильянович',
      years: '1926-2016',
      description: 'Муж ААлександрова Александры Валентиновны, родился в Деревне Иваново, в семье работников колхоза “50 лет ВЛКСМ”...'
    },
  ]

  constructor(){}



}
