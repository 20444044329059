<section>
  <h2>Родственники</h2>
  <swiper-container
    pagination="true"
    pagination-clickable="true"
    css-mode="true"
    autoplay-delay="5000"
    autoplay-disable-on-interaction="false"
    space-between="30"
    slides-per-view="3"
  >
    <swiper-slide class="mb-3" *ngFor="let relative of relatives">
      <div class="card-container">
        <img src="{{relative['photo']}}" alt="" />
        <div class="title">{{relative['title']}}</div>
        <div class="years">{{relative['years']}}</div>
        <div class="hline"></div>
        <div class="description">{{relative['description']}}</div>
        <div class="more">
          <div class="hline"></div>
          <button>подробнее</button>
          <div class="hline"></div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</section>
